<template>
  <v-row>
    <v-col cols="12">
      <Search :tab="tab" :params="params" :options="options" @onSearch="getData(1)" @onReset="reset" />
    </v-col>
    <v-col cols="12">
      <DataTable
        :height="$vuetify.breakpoint.md ? undefined : 'calc(100vh - 320px)'"
        :form="form"
        :table-data="tableData"
        :pagination="pagination"
        :params="params"
        :tab="tab"
        @on-search="getData(1)"
        @on-edit="showDialogForm('edit', $event)"
        @on-create="showDialogForm('create')"
        @on-export="exportData"
        @deleted="getData()"
        @updated="getData()"
        @refreshed="getData()"
        @sorted="handleSort"
      />
    </v-col>
    <v-col cols="12">
      <Pagination
        :length="pagination.lastPage"
        :total="pagination.total"
        :params="params"
        @onPageChange="getData"
        @onPerPageChange="getData(1)"
      />
    </v-col>
  </v-row>
</template>

<script>
import DataTable from './components/DataTable.vue'
import Search from './components/Search.vue'
import Pagination from '@/components/Pagination.vue'
import { getCdLogs, exportCdLogs } from '@/api/log'
import indexMixin from '@/mixins/crud/index'

export default {
  components: {
    DataTable,
    Search,
    Pagination,
  },

  mixins: [indexMixin(getCdLogs, {}, exportCdLogs)],

  data() {
    return {
      model: 'cd-log',
      showDialog: false,
      editing: false,
      defaultParams: {
        page: 1,
        perPage: 20,
        sortBy: 'id:desc',
        with: 'user:id*email*name',
        action: null,
      },
      form: {},
      tab: 0,
    }
  },
}
</script>
