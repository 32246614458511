<template>
  <v-row>
    <v-col cols="12" md="6" lg="2">
      <DateRangePicker
        v-model="params.dateRange"
        :max="new Date().toISOString().slice(0, 10)"
        display-format="MMM dd, yyyy"
        label="Date Range"
        placeholder="Pick 2 Dates"
        outlined
        hide-details
        dense
        @input="$emit('onSearch')"
      />
    </v-col>

    <v-col cols="12" md="6" lg="2">
      <v-select
        clearable
        v-model="params.type"
        :items="[
          { text: 'Web', value: 'web' },
          { text: 'Mobile', value: 'mobile' },
        ]"
        label="Login on"
        outlined
        hide-details
        dense
        @change="$emit('onSearch')"
      ></v-select>
    </v-col>
    <v-col cols="12" md="6" lg="2">
      <v-select
        clearable
        v-model="params.status"
        :items="[
          { text: 'Success', value: 'success' },
          { text: 'Failed', value: 'failed' },
        ]"
        label="Status"
        outlined
        hide-details
        dense
        @change="$emit('onSearch')"
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
import DateRangePicker from '@/components/DateRangePicker.vue'
export default {
  props: ['params', 'options'],
  components: { DateRangePicker },
}
</script>
<style lang=""></style>
