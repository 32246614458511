<template>
  <v-container fluid class="px-5">
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h1 class="font-weight-medium">Log Management</h1>
        <v-btn-toggle mandatory v-model="tab" class="ml-auto" tile color="deep-purple accent-3">
          <v-btn :value="0"> User Log </v-btn>
          <v-btn :value="1"> Data Log </v-btn>
          <v-btn :value="2"> CD Log </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <user-log v-if="tab === 0" />
    <data-log v-if="tab === 1" />
    <cd-log v-if="tab === 2" />
  </v-container>
</template>
<script>
import UserLog from './user-log/index.vue'
import DataLog from './data-log/index.vue'
import CdLog from './cd-log/index.vue'
export default {
  components: { UserLog, DataLog, CdLog },
  data() {
    return {
      tab: 0,
    }
  },
}
</script>
