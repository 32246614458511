import request from '@/services/request'
export function getUserLogs(params) {
  return request({
    url: `/user-logs`,
    method: 'get',
    params,
  })
}
export function exportUserLogs(params) {
  return request({
    url: `/user-logs/export`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}

export function getDataLogs(params) {
  return request({
    url: `/data-logs`,
    method: 'get',
    params,
  })
}

export function exportDataLogs(params) {
  return request({
    url: `/data-logs/export`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}

export function getCdLogs(params) {
  return request({
    url: `/cd-logs`,
    method: 'get',
    params,
  })
}
export function exportCdLogs(params) {
  return request({
    url: `/cd-logs/export`,
    method: 'get',
    params,
    responseType: 'blob',
  })
}
