<template>
  <v-data-table
    :style="{ height }"
    height="calc(100% - 48px)"
    :headers="getHeaders()"
    :items="tableData"
    disable-pagination
    fixed-header
    :options.sync="sortOptions"
    calculate-widths
    hide-default-footer
    :server-items-length="1"
    disable-filtering
  >
    <template v-slot:top>
      <v-toolbar dense flat>
        <v-text-field
          v-if="search"
          v-model="params.search"
          style="max-width: 300px"
          label="Search"
          clearable
          hide-details
          dense
          :append-icon="icons.mdiMagnify"
          single-line
          @keyup.enter="$emit('on-search')"
        ></v-text-field>
        <v-spacer></v-spacer>

        <v-btn icon color="primary" @click="$emit('on-export')">
          <v-icon>{{ icons.mdiDownload }}</v-icon>
        </v-btn>
        <v-btn icon color="primary" @click="$emit('refreshed')">
          <v-icon>{{ icons.mdiRefresh }}</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.status="{ item }">
      <v-chip color="success" v-if="item.status === 'success'">Success</v-chip>
      <v-chip color="error" v-else>Failed</v-chip>
    </template>
    <template v-slot:item.createdAt="{ item }">
      <span>{{ item.createdAt | datetime }}</span>
    </template>
    <template v-slot:item.type="{ item }">
      <v-icon v-if="item.type === 'web'">{{ icons.mdiWeb }}</v-icon>
      <v-icon v-else>{{ icons.mdiCellphone }}</v-icon>
    </template>
  </v-data-table>
</template>
<script>
import dataTableMixin from '@/mixins/crud/data-table'
import { mdiWeb, mdiCellphone } from '@mdi/js'
export default {
  mixins: [dataTableMixin()],
  props: {
    tab: Number,
  },
  data() {
    return { icons: { mdiWeb, mdiCellphone } }
  },
  computed: {
    headers() {
      switch (this.tab) {
        case 0:
          return [
            { text: 'ID', value: 'id' },
            { text: 'IP', value: 'ip' },
            { text: 'User', value: 'user.email', sortable: false },
            { text: 'Detection ID', value: 'cdResultId' },
            { text: 'Action', value: 'action' },
            { text: 'Time', value: 'createdAt' },
          ]
      }
      return headers
    },
  },
}
</script>
